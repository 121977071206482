import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col,
  Alert,
  Spin,
  Typography,
} from "antd";

const { Title, Text } = Typography;

const Address = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);

  const handleSubmit = async (values) => {
    setLoading(true);
    setStatus(null); // Reset the status before submitting

    try {
      const response = await fetch("/api/addresses/check-address", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        const data = await response.json();
        setStatus({
          type: "success",
          message: `Address submitted successfully! Response: ${JSON.stringify(
            data
          )}`,
        });
      } else {
        const errorData = await response.text();
        setStatus({
          type: "error",
          message: `Failed to submit the form! ${errorData}`,
        });
        console.error("Error Response:", errorData);
      }
    } catch (error) {
      setStatus({
        type: "error",
        message: "Error while submitting the form!",
      });
      console.error("Fetch Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f0f2f5",
      }}
    >
      <Card
        title={<Title level={3}>Find Addresses</Title>}
        style={{
          maxWidth: 800,
          boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
          width: "100%",
        }}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name="uprn" label="UPRN">
                <Input placeholder="Enter UPRN" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="organisationName" label="Organisation Name">
                <Input placeholder="Enter Organisation Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="departmentName" label="Department Name">
                <Input placeholder="Enter Department Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="subBuildingName" label="Sub-Building Name">
                <Input placeholder="Enter Sub-Building Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="buildingName" label="Building Name">
                <Input placeholder="Enter Building Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="buildingNumber" label="Building Number">
                <Input placeholder="Enter Building Number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="dependentThoroughfareName"
                label="Dependent Thoroughfare Name"
              >
                <Input placeholder="Enter Dependent Thoroughfare Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="thoroughfareName" label="Thoroughfare Name">
                <Input placeholder="Enter Thoroughfare Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="doubleDependentLocality"
                label="Double-Dependent Locality"
              >
                <Input placeholder="Enter Double-Dependent Locality" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="dependentLocality" label="Dependent Locality">
                <Input placeholder="Enter Dependent Locality" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="postCode"
                label="Post Code"
                rules={[
                  { required: "true", message: "Please enter postcode!" },
                ]}
              >
                <Input placeholder="Enter Post Code" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="postTown" label="Post Town">
                <Input placeholder="Enter Post Town" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} block>
              Submit
            </Button>
          </Form.Item>
        </Form>

        {loading && (
          <div style={{ marginTop: 20, textAlign: "center" }}>
            <Spin size="large" />
            <Text type="secondary" style={{ display: "block", marginTop: 10 }}>
              Submitting your address...
            </Text>
          </div>
        )}

        {status && !loading && (
          <Alert
            style={{ marginTop: 20 }}
            message={status.message}
            type={status.type}
            showIcon
          />
        )}
      </Card>
    </div>
  );
};

export default Address;
