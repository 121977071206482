// import React, { useState } from "react";
// import { Form, Input, Button, Card, Row, Col, message } from "antd";

// const ProductAvailability = () => {
//   const [form] = Form.useForm();
//   const [loading, setLoading] = useState(false);

//   const handleSubmit = async (values) => {
//     setLoading(true);
//     try {
//       const response = await fetch(
//         "http://192.168.1.39:3000/addresses/get-all-product-availablity",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(values),
//         }
//       );

//       if (response.ok) {
//         const data = await response.json();
//         message.success(`Products fetched successfully! ${JSON.stringify(data)}`);
//       } else {
//         const errorData = await response.text();
//         message.error(`Failed to fetch product availability! ${errorData}`);
//         console.error("Error Response:", errorData);
//       }
//     } catch (error) {
//       message.error("Error while fetching product availability!");
//       console.error("Fetch Error:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <Card
//       title="Product Availability"
//       style={{
//         maxWidth: 800,
//         margin: "0 auto",
//         boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
//       }}
//     >
//       <Form form={form} layout="vertical" onFinish={handleSubmit}>
//         <Row gutter={[16, 16]}>
//           <Col span={12}>
//             <Form.Item
//               name="cssDistrictCode"
//               label="CSS District Code"
//               rules={[
//                 {message: "Please enter CSS District Code!" },
//               ]}
//             >
//               <Input placeholder="Enter CSS District Code" />
//             </Form.Item>
//           </Col>
//           <Col span={12}>
//             <Form.Item
//               name="alk"
//               label="ALK"
//               rules={[{ message: "Please enter ALK!" }]}
//             >
//               <Input placeholder="Enter ALK" />
//             </Form.Item>
//           </Col>
//           <Col span={12}>
//             <Form.Item
//               name="uprn"
//               label="UPRN"
//               rules={[{ message: "Please enter UPRN!" }]}
//             >
//               <Input placeholder="Enter UPRN" />
//             </Form.Item>
//           </Col>
//         </Row>
//         <Form.Item>
//           <Button type="primary" htmlType="submit" loading={loading} block>
//             Submit
//           </Button>
//         </Form.Item>
//       </Form>
//     </Card>
//   );
// };

// export default ProductAvailability;

import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col,
  Alert,
  Spin,
  Typography,
  Select,
} from "antd";

const { Title } = Typography;

const ProductAvailability = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [selectedType, setSelectedType] = useState("");

  const handleChange = (value) => {
    setSelectedType(value);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    setStatus(null);

    try {
      const response = await fetch(
        "/api/addresses/get-all-product-availablity",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setStatus({
          type: "success",
          message: `Products fetched successfully! Response: ${JSON.stringify(
            data
          )}`,
        });
      } else {
        const errorData = await response.text();
        setStatus({
          type: "error",
          message: `Failed to fetch product availability! Error: ${errorData}`,
        });
      }
    } catch (error) {
      setStatus({
        type: "error",
        message: "Error while fetching product availability!",
      });
      console.error("Fetch Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f0f2f5",
      }}
    >
      <Card
        title={
          <Title level={3} style={{ textAlign: "center" }}>
            Product Availability
          </Title>
        }
        style={{
          maxWidth: 800,
          margin: "0 auto",
          boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
        }}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="addressType"
                label="Address type"
                rules={[{ required:"true",message: "Please enter Address type!" }]}
              >
                <Select placeholder="Select Order Type" onChange={handleChange}>
                  <Select.Option key={"GALK"} value={"GALK"}>
                    GALK
                  </Select.Option>
                  <Select.Option key={"UPRN"} value={"UPRN"}>
                    UPRN
                  </Select.Option>
                  <Select.Option key={"GALK-UPRN"} value={"GALK-UPRN"}>
                    GALK-UPRN
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            {(selectedType === "GALK" || selectedType === "GALK-UPRN") && (
              <>
                <Col span={12}>
                  <Form.Item
                    name="cssDistrictCode"
                    label="CSS District Code"
                    rules={[{ required:"true", message: "Please enter CSS District Code!" }]}
                  >
                    <Input placeholder="Enter CSS District Code" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="alk"
                    label="ALK"
                    rules={[{ required:"true",message: "Please enter ALK!" }]}
                  >
                    <Input placeholder="Enter ALK" />
                  </Form.Item>
                </Col>
              </>
            )}

            {(selectedType === "UPRN" || selectedType === "GALK-UPRN") && (
              <>
                <Col span={12}>
                  <Form.Item
                    name="uprn"
                    label="UPRN"
                    rules={[{required:"true", message: "Please enter UPRN!" }]}
                  >
                    <Input placeholder="Enter UPRN" />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} block>
              Submit
            </Button>
          </Form.Item>
        </Form>

        {loading && (
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <Spin size="large" />
            <Typography.Text
              type="secondary"
              style={{ display: "block", marginTop: 10 }}
            >
              Fetching product availability...
            </Typography.Text>
          </div>
        )}

        {status && !loading && (
          <Alert
            style={{ marginTop: 20 }}
            message={status.message}
            type={status.type}
            showIcon
          />
        )}
      </Card>
    </div>
  );
};

export default ProductAvailability;
