import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  TimePicker,
  Card,
  Row,
  Col,
  Alert,
  Spin,
  Typography,
  Select,
} from "antd";

const ReserveAppointment = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [selectedType, setSelectedType] = useState("");

  const handleChange = (value) => {
    setSelectedType(value);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    setStatus(null);

    if (values.appointmentDate) {
      values.appointmentDate = values.appointmentDate.format("YYYY-MM-DD");
    }
    if (values.appointmentStartTime) {
      values.appointmentStartTime =
        values.appointmentStartTime.format("HH:mm");
    }
    if (values.appointmentEndTime) {
      values.appointmentEndTime = values.appointmentEndTime.format("HH:mm");
    }

    try {
      const response = await fetch("/api/appointment/reserve-appointment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        const data = await response.json();
        setStatus({
          type: "success",
          message: `Appointment reserved successfully! Response: ${JSON.stringify(
            data
          )}`,
        });
      } else {
        const errorData = await response.text();
        setStatus({
          type: "error",
          message: `Failed to reserve appointment! Error: ${errorData}`,
        });
        console.error("Error Response:", errorData);
      }
    } catch (error) {
      setStatus({
        type: "error",
        message: "Error while reserving appointment!",
      });
      console.error("Fetch Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      title="Reserve Appointment"
      style={{
        maxWidth: 800,
        margin: "0 auto",
        boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
      }}
    >
      {loading && (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Spin size="large" />
          <Typography.Text
            type="secondary"
            style={{ display: "block", marginTop: 10 }}
          >
            Reserving appointment...
          </Typography.Text>
        </div>
      )}

      {status && !loading && (
        <Alert
          style={{ marginTop: 20 }}
          message={status.message}
          type={status.type}
          showIcon
        />
      )}

      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          provisioningCommand: "ProvideNew", // Set the default value here
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name="type"
              label="Type"
              rules={[
                { required: "true", message: "Please enter Address type!" },
              ]}
            >
              <Select placeholder="Select Order Type" onChange={handleChange}>
                <Select.Option key={"CF"} value={"CF"}>
                  CITY FIBRE
                </Select.Option>
                <Select.Option key={"SOGEA"} value={"SOGEA"}>
                  SOGEA
                </Select.Option>
                <Select.Option key={"OR"} value={"OR"}>
                  OPEN REACH
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="provisioningCommand"
              label="Provisioning Command"
              rules={[{ message: "Please enter Provisioning Command!" }]}
            >
              <Input placeholder="Enter Provisioning Command" />
            </Form.Item>
          </Col>
          {selectedType == "CF" && (
            <Col span={12}>
              <Form.Item
                name="uprn"
                label="UPRN"
                rules={[{ required: "true", message: "Please enter UPRN!" }]}
              >
                <Input placeholder="Enter UPRN" />
              </Form.Item>
            </Col>
          )}
          {(selectedType == "SOGEA" || selectedType == "OR") && (
            <>
              <Col span={12}>
                <Form.Item
                  name="galk"
                  label="GALK"
                  rules={[{ required: "true", message: "Please enter GALK!" }]}
                >
                  <Input placeholder="Enter GALK" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="districtCode"
                  label="District Code"
                  rules={[
                    {
                      required: "true",
                      message: "Please enter District Code!",
                    },
                  ]}
                >
                  <Input placeholder="Enter District Code" />
                </Form.Item>
              </Col>
            </>
          )}
          <Col span={12}>
            <Form.Item
              name="appointmentDate"
              label="Appointment Date"
              rules={[{ required: "true",message: "Please select an Appointment Date!" }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="appointmentTimeSlot"
              label="Appointment Time Slot"
              rules={[{ required: "true",message: "Please enter Appointment Time Slot!" }]}
            >
              <Input placeholder="Enter Appointment Time Slot" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="appointmentStartTime"
              label="Appointment Start Time"
              rules={[{ required: "true",message: "Please select Appointment Start Time!" }]}
            >
              <TimePicker format="HH:mm" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="appointmentEndTime"
              label="Appointment End Time"
              rules={[{ required: "true",message: "Please select Appointment End Time!" }]}
            >
              <TimePicker format="HH:mm" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            Reserve
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default ReserveAppointment;
