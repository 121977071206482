import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Components/Home/Home";
import PingServer from "./Components/Address/pingserver";
import Address from "./Components/Address/Address";
import ProductAvailability from "./Components/Address/proavail";
import 'antd/dist/reset.css';
import GetAppointment from "./Components/Appointment/getappointment";
import ReserveAppointment from "./Components/Appointment/reserveappointment";
import CreateOrder from "./Components/Product Ordering/createorder"

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ping-server" element={<PingServer />} />
        <Route path="/address" element={<Address />} />
        <Route path="/prodAvail" element={<ProductAvailability />} />
        <Route path="/getAppointment" element={<GetAppointment />} />
        <Route path="/reserve" element={<ReserveAppointment />} />
        <Route path="/create" element={<CreateOrder/>} />
      </Routes>
    </Router>
 
  );
};

export default App;
