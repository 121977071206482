import React,{useState} from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button, Typography, Space } from "antd";
import { 
  SearchOutlined, 
  CloudServerOutlined,
  ShoppingCartOutlined,
  CheckCircleOutlined,
  CalendarOutlined,
  ScheduleOutlined    
} from "@ant-design/icons";

const { Title } = Typography;

const Home = () => {
  const navigate = useNavigate();
  const [hoveredButton, setHoveredButton] = useState(null);

  const getButtonStyle = (buttonKey) => ({
    backgroundColor: hoveredButton === buttonKey ? "#1890ff" : "white",
    color: hoveredButton === buttonKey ? "white" : "#000",
    borderColor: hoveredButton === buttonKey ? "#1890ff" : "#d9d9d9",
    transition: "all 0.3s",
  });
  return (
    <div 
      style={{
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center", 
        minHeight: "100vh", 
        backgroundColor: "#f0f2f5"
      }}
    >
      <Card
        style={{
          width: 400,
          textAlign: "center",
          boxShadow: "0 4px 6px rgba(0,0,0,0.1)"
        }}
      >
        <Title level={2} style={{ marginBottom: 24 }}>
          Address Management
        </Title>
        
        <Space direction="vertical" style={{ width: "100%" }}>
          <Button 
            // type="default" 
            icon={<CloudServerOutlined />}
            size="large"
            // className="hover-primary"
            block
            style={getButtonStyle("pingServer")}
            onMouseEnter={() => setHoveredButton("pingServer")}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={() => navigate("/ping-server")}
          >
            Ping Server
          </Button>
          
          <Button 
            // type="default" 
            icon={<SearchOutlined />}
            size="large"
            // className="hover-primary"
            block
            style={getButtonStyle("findAddress")}
            onMouseEnter={() => setHoveredButton("findAddress")}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={() => navigate("/address")}
          >
            Find Available Address
          </Button>
          <Button 
            // type="default" 
            icon={<CheckCircleOutlined  />}
            size="large"
            // className="hover-primary"
            block
            style={getButtonStyle("prodAvail")}
            onMouseEnter={() => setHoveredButton("prodAvail")}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={() => navigate("/prodAvail")}
          >
            Product Availability
          </Button>
        </Space>
      </Card>
      <Card style={{
          width: 400,
          textAlign: "center",
          boxShadow: "0 4px 6px rgba(0,0,0,0.1)"
        }}>
            <Title level={2} style={{ marginBottom: 24 }}>
         Appointment
        </Title>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Button 
            // type="default" 
            icon={<CloudServerOutlined />}
            size="large"
            // className="hover-primary"
            block
            style={getButtonStyle("pingAppointment")}
            onMouseEnter={() => setHoveredButton("pingAppointment")}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={() => navigate("/ping-server")}
          >
            Ping Server
          </Button>
          
          <Button 
            // type="default" 
            icon={<CalendarOutlined  />}
            size="large"
            // className="hover-primary"
            block
            style={getButtonStyle("get")}
            onMouseEnter={() => setHoveredButton("get")}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={() => navigate("/getAppointment")}
          >
            Get Appointment
          </Button>
          <Button 
            // type="default" 
            icon={<ScheduleOutlined  />}
            size="large"
            // className="hover-primary"
            block
            style={getButtonStyle("reserve")}
            onMouseEnter={() => setHoveredButton("reserve")}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={() => navigate("/reserve")}
          >
            Reserve Appointment
          </Button>
        </Space>

      </Card>
      <Card style={{
          width: 400,
          textAlign: "center",
          boxShadow: "0 4px 6px rgba(0,0,0,0.1)"
        }}>
            <Title level={2} style={{ marginBottom: 24 }}>
         Product Ordering
        </Title>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Button 
            // type="default" 
            icon={<ShoppingCartOutlined  />}
            size="large"
            // className="hover-primary"
            block
            style={getButtonStyle("create")}
            onMouseEnter={() => setHoveredButton("create")}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={() => navigate("/create")}
          >
            Create Order
          </Button>
        </Space>

      </Card>
    </div>
  );
};

export default Home;