import React, { useState } from "react";
import { Form, Select, Input, Button, Card, Row, Col, message } from "antd";

const CreateOrder = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await fetch(
        "http://192.168.1.39:3000/order/create-order",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );

      if (response.ok) {
        const data = await response.json();
        message.success(`Order created successfully! ${JSON.stringify(data)}`);
      } else {
        const errorData = await response.text();
        message.error(`Failed to create order! ${errorData}`);
        console.error("Error Response:", errorData);
      }
    } catch (error) {
      message.error("Error while creating the order!");
      console.error("Fetch Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const dropdownOptions = [
    "",
    "CFH-FTTP-NEWLINE",
    "OR-FTTP-NEWLINE",
    "OR-SOGEA-NEWLINE",
    "OR-FTTP-Switch",
    "OR-FTTP-SwitchToStop",
    "OR-FTTP-SwitchToNew",
    "createProductOrder-SOGEA-Switch-DynamicIP",
    "createProductOrder-SOGEA-Replace",
    "createProductOrder-SOGEA-Restart",
    "createProductOrder-SOGEA-Takeover",
    "suspend-full-L2",
    "suspendProduct-partial",
    "unsuspendProduct",
    "ceaseProduct",
    "createProductOrder-Openreach-Restart",
    "createProductOrder-Openreach-TakeOver",
    "createProductOrder-Openreach-ReplaceToStop",
    "createProductOrder-Openreach-ReplaceToNew",
  ];

  return (
    <Card
      title="Create Order"
      style={{
        maxWidth: 800,
        margin: "0 auto",
        boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
      }}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
            //   name="orderType"
              label="Heal Action"
              rules={[{ message: "Please select an Order Type!" }]}
            >
              <Select placeholder="Select Order Type">
                {dropdownOptions.map((option) => (
                  <Select.Option key={option} value={option}>
                    {option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="galk"
              label="GALK"
              rules={[{ message: "Please enter GALK ID!" }]}
            >
              <Input placeholder="Please enter GALK ID!" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="districtCode"
              label="District Code"
              rules={[{ message: "Please enter District Code!" }]}
            >
              <Input placeholder="Enter District Code" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="locality"
              label="Locality"
              rules={[{ message: "Please enter Locality!" }]}
            >
              <Input type="text" placeholder="Enter Locality" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="postcode"
              label="Postcode"
              rules={[{ message: "Please enter Postcode!" }]}
            >
              <Input  placeholder="Enter Postcode" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="streetName"
              label="Street Name"
              rules={[{ message: "Please enter Street Name!" }]}
            >
              <Input placeholder="Enter Street Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="streetNr"
              label="Street Number"
              rules={[{ message: "Please enter Street Number!" }]}
            >
              <Input type="number" placeholder="Enter Street Number" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="buildingName"
              label="Building Name"
              rules={[{ message: "Please enter Building Name!" }]}
            >
              <Input type="text" placeholder="Enter Building Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="subUnitNumber"
              label="Sub Unit Number"
              rules={[{ message: "Please enter Sub Unit Number!" }]}
            >
              <Input type="number" placeholder="Enter Sub Unit Number" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading} block>
                Create Order
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default CreateOrder;
