import React, { useState } from "react";
import { Card, Button, Typography, Alert, Spin } from "antd";
import { CloudServerOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const PingServer = () => {
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  const handlePing = async () => {
    setLoading(true);
    setStatus(null);

    try {
      const response = await fetch("/api/addresses/ping-server", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        setStatus({
          type: "success",
          message: `Server is up! Response: ${JSON.stringify(data)}`
        });
      } else {
        setStatus({
          type: "error",
          message: "Server is down or unreachable!"
        });
      }
    } catch (error) {
      setStatus({
        type: "error",
        message: "Error connecting to the server!"
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div 
      style={{
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center", 
        minHeight: "100vh", 
        backgroundColor: "#f0f2f5"
      }}
    >
      <Card
        style={{
          width: 400,
          textAlign: "center",
          boxShadow: "0 4px 6px rgba(0,0,0,0.1)"
        }}
      >
        <Title level={2} style={{ marginBottom: 24 }}>
          <CloudServerOutlined style={{ marginRight: 10 }} />
          Ping Server
        </Title>

        <Button 
          type="primary" 
          icon={<CloudServerOutlined />}
          size="large"
          block
          onClick={handlePing}
          loading={loading}
          disabled={loading}
        >
          Ping Server
        </Button>

        {loading && (
          <div style={{ marginTop: 20, textAlign: "center" }}>
            <Spin size="large" />
            <Text type="secondary" style={{ display: "block", marginTop: 10 }}>
              Connecting to server...
            </Text>
          </div>
        )}

        {status && !loading && (
          <Alert
            style={{ marginTop: 20 }}
            message={status.message}
            type={status.type}
            showIcon
          />
        )}
      </Card>
    </div>
  );
};

export default PingServer;