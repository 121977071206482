import React, { useState } from "react";

import {
  Form,
  Input,
  Button,
  DatePicker,
  Card,
  Row,
  Col,
  Select,
  Typography,
  Spin,
  Alert,
} from "antd";
const { Title } = Typography;

const GetAppointment = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [selectedType, setSelectedType] = useState("");

  const handleChange = (value) => {
    setSelectedType(value);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    setStatus(null);

    if (values.appointmentDate) {
      values.appointmentDate = values.appointmentDate.format("YYYY-MM-DD");
    }

    try {
      const response = await fetch("/api/appointment/get-appointment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        const data = await response.json();

        setStatus({
          type: "success",
          message: `Appointment fetched successfully! Response: ${JSON.stringify(
            data
          )}`,
        });

        // message.success(
        //   `Appointment fetched successfully! ${JSON.stringify(data)}`
        // );
      } else {
        const errorData = await response.text();
        setStatus({
          type: "error",
          message: `Failed to fetch appointment! Error: ${errorData}`,
        });
        console.error("Error Response:", errorData);
      }
    } catch (error) {
      setStatus({
        type: "error",
        message: "Error while fetching appointment!",
      });
      console.error("Fetch Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          backgroundColor: "#f0f2f5",
        }}
      >
        <Card
          title={
            <Title level={3} style={{ textAlign: "center" }}>
              Get Appointment
            </Title>
          }
          style={{
            maxWidth: 800,
            margin: "0 auto",
            boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
          }}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={{
              provisioningCommand: "ProvideNew", // Set the default value here
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  name="type"
                  label="Type"
                  rules={[
                    { required: "true", message: "Please enter Address type!" },
                  ]}
                >
                  <Select
                    placeholder="Select Order Type"
                    onChange={handleChange}
                  >
                    <Select.Option key={"CF"} value={"CF"}>
                      CITY FIBRE
                    </Select.Option>
                    <Select.Option key={"SOGEA"} value={"SOGEA"}>
                      SOGEA
                    </Select.Option>
                    <Select.Option key={"OR"} value={"OR"}>
                      OPEN REACH
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="provisioningCommand"
                  label="Provisioning Command"
                  rules={[
                    {
                      required: "true",
                      message: "Please enter Provisioning Command!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Provisioning Command" />
                </Form.Item>
              </Col>
              {selectedType == "CF" && (
                <Col span={12}>
                  <Form.Item
                    name="uprn"
                    label="UPRN"
                    rules={[
                      { required: "true", message: "Please enter UPRN!" },
                    ]}
                  >
                    <Input placeholder="Enter UPRN" />
                  </Form.Item>
                </Col>
              )}
              {(selectedType == "SOGEA" || selectedType == "OR") && (
                <>
                  <Col span={12}>
                    <Form.Item
                      name="galk"
                      label="GALK"
                      rules={[
                        { required: "true", message: "Please enter GALK!" },
                      ]}
                    >
                      <Input placeholder="Enter GALK" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="districtCode"
                      label="District Code"
                      rules={[
                        {
                          required: "true",
                          message: "Please enter District Code!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter District Code" />
                    </Form.Item>
                  </Col>
                </>
              )}
              <Col span={12}>
                <Form.Item
                  name="appointmentDate"
                  label="Appointment Date"
                  rules={[
                    {
                      required: "true",
                      message: "Please select an Appointment Date!",
                    },
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading} block>
                Submit
              </Button>
            </Form.Item>
          </Form>

          {loading && (
            <div style={{ textAlign: "center", marginTop: 20 }}>
              <Spin size="large" />
              <Typography.Text
                type="secondary"
                style={{ display: "block", marginTop: 10 }}
              >
                Fetching appointment details...
              </Typography.Text>
            </div>
          )}

          {status && !loading && (
            <Alert
              style={{ marginTop: 20 }}
              message={status.message}
              type={status.type}
              showIcon
            />
          )}
        </Card>
      </div>    
    </>
  );
};

export default GetAppointment;
